import React from "react";
import { makeStyles } from '@material-ui/core/styles';
//import ReactDOM from "react-dom";
//import Typical from 'react-typical';
import "./styles.css";
import LogoElMaik from "../../assets/Logo Burros Y Tacos El Maik.png";
import { Box } from '@material-ui/core';
import Typical from 'react-typical';
import Facebook from "../../assets/Logo_Facebook.png";
import Instagram from "../../assets/Logo_Instagram.png";
import TikTok from "../../assets/Logo_TikTok.png";

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#ffffff",
        margin: "0px 5px",
        color: "#000000",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    }
}))

function Construccion() {

    // Inicio 
    const classes = useStyles();
    return (
        <div>
            {
                <div className={classes.otro}>
                    <div className="App">
                        <header className="App-construccion">

                            <a href="https://burrosytacoselmaik.com" target="_self" rel="noreferrer">
                                <Box
                                    component="img"
                                    sx={{
                                        height: "50%",
                                        width: "50%",
                                    }}
                                    alt="LogoElMaik."
                                    src={LogoElMaik}
                                />
                            </a>

                            {/*<h2><code>Proximamente...</code></h2>*/}
                            <Typical
                                steps={[
                                    'Construyendo...', 1500,
                                    'Próximamente...', 1500]}
                                loop={Infinity}
                                wrapper="p"
                            />

                            <Box >
                                <a href="https://www.facebook.com/BurrosyTacosElMaik/" target="_blank" rel="noreferrer">
                                    <Box
                                        component="img"
                                        sx={{
                                            marginRight: "10px",
                                            height: "5vh",
                                            width: "5vh",
                                            maxHeight: { xs: 100, md: 100 },
                                            maxWidth: { xs: 100, md: 100 },
                                        }}
                                        alt="Facebook."
                                        src={Facebook}
                                    />
                                </a>
                                <a href="https://www.instagram.com/burrosytacoselmaik/" target="_blank" rel="noreferrer">
                                    <Box
                                        component="img"
                                        sx={{
                                            height: "5vh",
                                            width: "5vh",
                                            maxHeight: { xs: 100, md: 100 },
                                            maxWidth: { xs: 100, md: 100 },
                                        }}
                                        alt="Instagram."
                                        src={Instagram}
                                    />
                                </a>
                                <a href="https://www.tiktok.com/@burrosytacoselmaik" target="_blank" rel="noreferrer">
                                    <Box
                                        component="img"
                                        sx={{
                                            marginLeft: "10px",
                                            height: "5vh",
                                            width: "5vh",
                                            maxHeight: { xs: 100, md: 100 },
                                            maxWidth: { xs: 100, md: 100 },
                                        }}
                                        alt="TikTok."
                                        src={TikTok}
                                    />
                                </a>
                            </Box>


                        </header>
                    </div>
                </div>
            }
        </div>
    );
}

export default Construccion;