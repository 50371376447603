import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import "./styles.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';

import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import Menu from "../../assets/icons/Burro.png";
import Ubicacion from "../../assets/icons/Ubicacion_google_logo.png";
import { ReactComponent as RedesSVG } from '../../assets/icons/Redes_SVG.svg';
import CommentIcon from '@mui/icons-material/Comment';

import Facebook from "../../assets/Logo_Facebook.png";
import Instagram from "../../assets/Logo_Instagram.png";
import TikTok from "../../assets/Logo_TikTok.png";

import { FacebookEmbed, InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';

import LogoElMaik from "../../assets/Logo Burros Y Tacos El Maik.png";


import { motion as m } from "framer-motion";

const alturaHome = 10;
const alturaRedes = 15;
const alturaiFrame = 75;
const AlturaPaddingDrawer = alturaHome / 2;
const TamanoIcono = 10;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))


function esconder() {
    //    document.getElementsByClassName("_2p3a").style.display = "none";
    //document.getElementsByClassName("_2p3a")[0].style.width = "100%";
    setTimeout(() => {
        console.log("HOLAAAAAAAAAAAAAA");
        //document.getElementsByClassName("_2p3a")[0].setAttribute("style", "width:100px;height:100px;background-color:#f00;");


        /*$("iframe").each(function(index){
            $(this).contents().find('._2p3a').css({width: '100%'});
         });
*/

        /*
                var iframe = document.getElementById("PruebaFacebook");
                var elmnt = iframe.contentWindow.document.getElementsByTagName("DIV")[0];
                elmnt.style.display = "none";*/



        // document.getElementById("PruebaFacebook").contentWindow.document.body.getElementById("innerIframeElement").setAttribute("style", "width:'100%';")



        /*        var Prueba = document.querySelector('._2p3a')
        
                Prueba.append.style.width = '100%'*/

        console.log("Adios");
        //document.getElementsByClassName("_2p3a")[0].style.width = "100%";
    }
        , 3000);
    console.log(5 + 6);
}



function Redes() {

    const iframe_container = {
        left: 0,
        width: "100%",
        height: 500,
        position: "relative"
    }

    const iframe = {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "absolute",
        border: 0
    }
    // Redes 
    const classes = useStyles();

    //document.getElementById("HomeTopBar").style.display = "block";
    //document.getElementsByClassName("_2p3a").style.display = "block";

    return (
        <div>
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden" }} onLoad={esconder()}>
                    <header className="App-header">


                        <Container className='h-100' style={{ padding: 0, margin: 0, width: "100%", height: "100%" }}>
                            <Row style={{ height: "10vh", width: "100vw", margin: 0 }}>

                                <Col className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaHome}vh`, width: "100vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/Inicio">
                                            <m.a
                                                initial={{ scale: 1.0000001 }}
                                                animate={{ scale: 1.0000001 }}
                                                transition={{ delay: 0.0, duration: 0.5 }}
                                                exit={{ scale: 2.5, y: "45.03vh" }}
                                                href="https://burrosytacoselmaik.com" target="_self" rel="noreferrer">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: "10vh",
                                                        width: "auto",
                                                        position: "absolute",
                                                        left: "50%",
                                                        top: "20vh",
                                                        transform: "translate(-50%, -25vh)",
                                                        zIndex: 100000
                                                    }}
                                                    alt="LogoElMaik."
                                                    src={LogoElMaik}
                                                />
                                            </m.a>
                                        </IconButton>
                                    </Box>
                                </Col>

                            </Row>
                            <Row style={{ height: "75vh", width: "100vw", margin: 0 }}>

                                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>


                                        <div className={iframe_container} style={{ width: "100%", height: "75vh" }}>
                                            <iframe
                                                src="https://www.instagram.com/p/CeAxC89FDj0/embed"
                                                className={iframe}
                                                style={{ width: "100%", height: "75vh" }}
                                                allowFullScreen
                                                scrolling="no"
                                                allow="encrypted-media;"
                                            ></iframe>
                                        </div>

                                    </Box>
                                </Col>

                                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 0vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>



                                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2Fpfbid02dw3rU4pahMWa6KRn3C3JivV3a2EDMz5n4aMmrcBAWE8VD5kbkQVnd33fomJs5uqal%2F&width=auto&show_text=true&height=500&appId" width="100%" height="500" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>


                                    </Box>
                                </Col>

                                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>

                                        <div className={iframe_container} style={{ width: "100%", height: "75vh" }}>
                                            <iframe
                                                src="https://www.tiktok.com/embed/7165255525880319237"
                                                className={iframe}
                                                style={{ width: "100%", height: "75vh" }}
                                                allowFullScreen
                                                scrolling="no"
                                                allow="encrypted-media;"
                                            ></iframe>
                                        </div>

                                    </Box>
                                </Col>

                            </Row>
                            <Row style={{ height: "15vh", width: "100vw", margin: 0 }}>

                                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaRedes}vh`, width: "33.4vw", borderRadius: 0 }} aria-label="home" size='large' onClick={() => window.open("https://www.instagram.com/burrosytacoselmaik/", "_blank")}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: `${alturaRedes}vh`,
                                                    width: `${alturaRedes}vh`,
                                                    maxHeight: { xs: 40, md: 100 },
                                                    maxWidth: { xs: 40, md: 100 },
                                                }}
                                                alt="Instagram."
                                                src={Instagram}
                                            />
                                        </IconButton>
                                    </Box>
                                </Col>

                                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaRedes}vh`, width: "33.4vw", borderRadius: 0 }} aria-label="home" size='large' onClick={() => window.open("https://www.facebook.com/BurrosyTacosElMaik/", "_blank")}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: `${alturaRedes}vh`,
                                                    width: `${alturaRedes}vh`,
                                                    maxHeight: { xs: 40, md: 100 },
                                                    maxWidth: { xs: 40, md: 100 },
                                                }}
                                                alt="Facebook."
                                                src={Facebook}
                                            />
                                        </IconButton>
                                    </Box>
                                </Col>

                                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaRedes}vh`, width: "33.4vw", borderRadius: 0 }} aria-label="home" size='large' onClick={() => window.open("https://www.tiktok.com/@burrosytacoselmaik", "_blank")}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: `${alturaRedes}vh`,
                                                    width: `${alturaRedes}vh`,
                                                    maxHeight: { xs: 40, md: 100 },
                                                    maxWidth: { xs: 40, md: 100 },
                                                }}
                                                alt="TikTok."
                                                src={TikTok}
                                            />
                                        </IconButton>
                                    </Box>
                                </Col>

                            </Row>
                        </Container>


                    </header>
                </div>
            </div>
        </div>
    );
}

export default Redes;