import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Construccion from '../Screens/Construccion'
import Login from '../Screens/Login'
import Inicio from '../Screens/Inicio'
import Menu from '../Screens/Menu'
import Ubicacion from '../Screens/Ubicacion'
import Redes from '../Screens/Redes'
import Otro from '../Screens/Otro'
import Carga from '../Screens/Carga'

import { AnimatePresence } from 'framer-motion'


const Routes = () => {
    const location = useLocation();
    return (
        <div>
            <AnimatePresence initial={true} mode={"wait"}>
                <Switch location={location} key={location.pathname}>
                    <Route path="/" exact>
                        <Carga />
                    </Route>
                    <Route exact path="/carga" component={Carga} />
                    <Route exact path="/construccion" component={Construccion} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/inicio" component={Inicio} />
                    <Route exact path="/menu" component={Menu} />
                    <Route exact path="/ubicacion" component={Ubicacion} />
                    <Route exact path="/redes" component={Redes} />
                    <Route exact path="/otro" component={Otro} />
                </Switch>
            </AnimatePresence>
        </div>
    )
}

export default Routes
