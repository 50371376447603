import React from "react";
import { makeStyles } from '@material-ui/core/styles';
//import ReactDOM from "react-dom";
//import Typical from 'react-typical';
import "./styles.css";
/*import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';*/

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';




import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';


import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';


import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


import Menu from "../../assets/icons/Burro_plato.png";
import Ubicacion from "../../assets/icons/Ubicacion_google_logo.png";
import { ReactComponent as RedesSVG } from '../../assets/icons/Redes_SVG.svg';
import CommentIcon from '@mui/icons-material/Comment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import LogoElMaik from "../../assets/Logo Burros Y Tacos El Maik.png";


import { motion as m } from "framer-motion";


const alturaHome = 100;
const alturaDrawerTop = 50;
const AlturaPaddingDrawer = alturaDrawerTop / 2;
const TamanoIcono = 10;


const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  Dentro: {
    width: "100px", height: "100px",
    position: "relative", float: "left",
    backgroundColor: "#000000",
    margin: "0px 5px",
    color: "#ffffff",
    fontWeight: "bold"
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "5px"
  },
  Container: {
    width: "100%"
  },
  Row: {
    marginRight: "0px"
  }
}))

function Inicio() {
  // Inicio 
  const classes = useStyles();

  //document.getElementById("HomeTopBar").style.display = "block";

  return (
    <m.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <div className={classes.otro}>
        <div className="App" style={{ overflowY: "hidden" }}>
          <header className="App-header">
            <Box //Header Icono Home
              id='HomeTopBar' justifyContent="center" display='flex'
              style={{
                margin: 0,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100000000
              }} >
              <m.a
                initial={{ scale: 1 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.0, duration: 0.5 }}
                exit={{ scale: 0.4, y: "-44.9vh" }}
                href="https://burrosytacoselmaik.com" target="_self" rel="noreferrer">
                <Box
                  component="img"
                  sx={{
                    height: "25vh",
                    width: "auto",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 100000
                  }}
                  alt="LogoElMaik."
                  src={LogoElMaik}
                />
              </m.a>
            </Box>

            <Container className='h-100' style={{ padding: 0, margin: 0, width: "100%", height: "100%" }}>
              <Row style={{ height: "100vh", width: "100vw", margin: 0 }}>

                <m.div style={{ padding: "0px", display: "flex" }}
                  className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  exit={{ scale: 0.0, y:"-50vh", x:"-50vw" }}
                >

                  <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                    <Box //Header Icono Home
                      id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                      <Tooltip TransitionComponent={Zoom} title="Menú" arrow placement="bottom">
                        <m.div
                          whileHover={{ scale: 1.2 }}
                          onHoverStart={e => { }}
                          onHoverEnd={e => { }}
                        >
                          <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/menu">

                            <Box
                              component="img"
                              sx={{
                                height: { xs: "auto", md: "auto" },
                                width: { xs: "auto", md: "auto" },
                                maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                //maxWidth: { xs: 48, md: 60 },
                              }}
                              alt="Menu."
                              src={Menu}
                            />
                          </IconButton>
                        </m.div>
                      </Tooltip>
                    </Box>
                  </Col>

                </m.div>
                <m.div style={{ padding: "0px", display: "flex" }}
                  className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  exit={{ scale: 0.0, y:"-50vh", x:"50vw" }}
                >
                <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                  <Box //Header Icono Home
                    id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                    <Tooltip TransitionComponent={Zoom} title="Ubicación" arrow placement="bottom">
                      <m.div
                        whileHover={{ scale: 1.2 }}
                        onHoverStart={e => { }}
                        onHoverEnd={e => { }}
                      >
                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/ubicacion">
                          <Box
                            component="img"
                            sx={{
                              height: { xs: "auto", md: "auto" },
                              width: { xs: "auto", md: "auto" },
                              maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                              maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw`, lg: `${TamanoIcono}vw` }
                              //maxWidth: { xs: 48, md: 60 },
                            }}
                            alt="Ubicacion."
                            src={Ubicacion}
                          />
                        </IconButton>
                      </m.div>
                    </Tooltip>
                  </Box>
                </Col>
                </m.div>
                <m.div style={{ padding: "0px", display: "flex" }}
                  className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  exit={{ scale: 0.0, y:"50vh", x:"-50vw" }}
                >
                <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                  <Box //Header Icono Home
                    id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                    <Tooltip TransitionComponent={Zoom} title="Redes" arrow placement="bottom">
                      <m.div
                        whileHover={{ scale: 1.2 }}
                        onHoverStart={e => { }}
                        onHoverEnd={e => { }}
                      >
                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/redes">
                          <RedesSVG width={`${TamanoIcono}vw`} fill='var(--text-primary)' />
                        </IconButton>
                      </m.div>
                    </Tooltip>
                  </Box>
                </Col>
                </m.div>
                <m.div style={{ padding: "0px", display: "flex" }}
                  className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  exit={{ scale: 0.0, y:"50vh", x:"50vw" }}
                >
                <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                  <Box //Header Icono Home
                    id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                    <Tooltip TransitionComponent={Zoom} title="Otro" arrow placement="bottom">
                      <m.div
                        whileHover={{ scale: 1.2 }}
                        onHoverStart={e => { }}
                        onHoverEnd={e => { }}
                      >
                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/otro">
                          <MoreHorizIcon /*fontSize="large"*/ sx={{ fontSize: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw`, lg: `${TamanoIcono}vw` }, color: "var(--text-primary)" }} />
                        </IconButton>
                      </m.div>
                    </Tooltip>
                  </Box>
                </Col>
                </m.div>


                {/*<Col className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6' style={{ boxShadow:"inset 0 0 100px orange", padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  4
                </Col>*/}

              </Row>
            </Container>


          </header>
        </div>
      </div>
    </m.div>
  );
}

export default Inicio;