import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import "./styles.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';

import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import MenuIcono from "../../assets/icons/Burro.png";
import UbicacionIcono from "../../assets/icons/Ubicacion_google_logo.png";
import { ReactComponent as RedesSVG } from '../../assets/icons/Redes_SVG.svg';
import CommentIcon from '@mui/icons-material/Comment';

import LogoElMaik from "../../assets/Logo Burros Y Tacos El Maik.png";

import { motion as m } from "framer-motion";

const alturaHome = 10;
const alturaRedes = 90;
const AlturaPaddingDrawer = alturaHome / 2;
const TamanoIcono = 10;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function Menu() {
    // Redes 
    const classes = useStyles();

    //document.getElementById("HomeTopBar").style.display = "block";

    return (
        <div>
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "visible" }}>
                    <header className="App-header">



                        <Container className='h-100' style={{ padding: 0, margin: 0, width: "100%", height: "100%" }}>
                            <Row style={{ height: "10vh", width: "100vw", margin: 0 }}>

                                <Col className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaHome}vh`, width: "100vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/Inicio">
                                            <m.a
                                                initial={{ scale: 1.0000001 }}
                                                animate={{ scale: 1.0000001 }}
                                                transition={{ delay: 0.0, duration: 0.5 }}
                                                exit={{ scale: 2.5, y: "45.03vh" }}
                                                href="https://burrosytacoselmaik.com" target="_self" rel="noreferrer">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: "10vh",
                                                        width: "auto",
                                                        position: "absolute",
                                                        left: "50%",
                                                        top: "20vh",
                                                        transform: "translate(-50%, -25vh)",
                                                        zIndex: 100000
                                                    }}
                                                    alt="LogoElMaik."
                                                    src={LogoElMaik}
                                                />
                                            </m.a>
                                        </IconButton>
                                    </Box>
                                </Col>

                            </Row>
                            <Row style={{ height: "90vh", width: "100vw", margin: 0 }}>

                                <Col className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12' style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                    <Box //Header Icono Home
                                        id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                        <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaRedes}vh`, width: "100vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/Redes">
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: { xs: "auto", md: "auto" },
                                                    width: { xs: "auto", md: "auto" },
                                                    maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                    maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                    //maxWidth: { xs: 48, md: 60 },
                                                }}
                                                alt="MenuIcono."
                                                src={MenuIcono}
                                            />
                                        </IconButton>
                                    </Box>
                                </Col>

                            </Row>
                        </Container>


                    </header>
                </div>
            </div>
        </div>
    );
}

export default Menu;